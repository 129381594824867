import React from "react";

const AppContext = React.createContext({
  installedApps: {},
  modals: [],
});

const AppContextConsumer = AppContext.Consumer;

class AppContextProvider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      installedApps: {},
      modals: [],
      loader: {
        hidden: true,
        textIndex: -1,
        image: null
      },
    };
  }

  render() {
    const state = {
      ...this.state,
      setInstalledApps: (apps) => {
        this.setState({installedApps: apps});
      },
      pushModal: (component, cb) => {
        const modals = this.state.modals.slice();
        modals.push(component);

        this.setState({modals}, () => cb && cb());
      },
      popModal: (cb) => {
        const modals = this.state.modals.slice();
        modals.pop();

        this.setState({modals}, () => cb && cb());
      },
      showLoader: (reset) => {
        this.setState({
          loader: {
            ...this.state.loader,
            hidden: false,
            textIndex: reset ? -1 : this.state.loader.textIndex,
            image: reset ? null : this.state.loader.image
          }
        });
      },
      hideLoader: () => {
        this.setState({
          loader: {
            ...this.state.loader,
            hidden: true
          }
        });
      },
      setLoaderTextIndex: (index) => {
        this.setState({
          loader: {
            ...this.state.loader,
            textIndex: index
          }
        });
      },
      setLoaderImage: (url) => {
        this.setState({
          loader: {
            ...this.state.loader,
            image: url
          }
        });
      },
    };

    return <AppContext.Provider
      value={state}
      children={this.props.children}
    />;
  }
}

export default AppContext;

export {AppContextConsumer, AppContextProvider};
