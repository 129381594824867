import * as amplitude from "amplitude-js";
import * as api from "./api";

const userProperties = {};
const userPropertiesCommitWaited = [];

Object.keys(window.clientConfig.splits).forEach((key) => {
  setUserProperty("split_" + key, window.clientConfig.splits[key]);
});

if (window.appConfig.amplitude.isEnabled) {
  amplitude.getInstance().init(window.appConfig.amplitude.key);
  amplitude.getInstance().setUserProperties({
    ...userProperties,
    client_is_webview: window.clientConfig.isWebview,
    client_platform: window.clientConfig.isWeb ? "web" : (window.clientConfig.isWebviewAndroid ? "android" : "ios"),
  });
}

// --

export const userEvents = {
  // юзер открыл таб с вебвью
  TAB_SELECTED: "tab_selected",

  // заход на страницу лендинга
  PAGE_INDEX: "page_index",

  // заход на страницу коллажа
  PAGE_COLLAGE: "page_collage",

  // юзер нажал на "попробовать другое фото"
  // параметры: page (страница: create, result, error)
  HOME_BUTTON_CLICK: "home_button_click",

  // юзер выбрал фото
  // параметры: page (страница: index, error)
  PHOTO_SELECT: "photo_select",

  // юзер успешно загрузил фото
  PHOTO_UPLOADED: "photo_uploaded",

  // юзер неуспешно загрузил фото
  PHOTO_UPLOAD_FAILED: "photo_upload_failed",

  // успешно закончился процессинг всех обработок
  // параметры: type (типа процессинга: генерация превьюшек (preview) или предколлажный (usual))
  PROCESSING_PROCESSED: "processing_processed",

  // неуспешно закончился процессинг всех обработок
  // параметры: type (типа процессинга: генерация превьюшек (preview) или предколлажный (usual))
  PROCESSING_FAILED: "processing_failed",

  // юзер выбран шаблон из предоставленого выбора после загрузки фотки
  // параметры: template_id (идентификатор шаблона)
  TEMPLATE_SELECT: "template_select",

  // коллаж успешно сгенерировался
  COLLAGE_PROCESSED: "collage_processed",

  // коллаж не сгенерировался
  COLLAGE_FAILED: "collage_failed",

  // юзер кликнул на кнопку шаринга
  // параметры:
  // id (наш идентификатор коллажа)
  // provider (название потока шаринга)
  // persons (кол-во персон на коллаже не считая юзера)
  // user_position_column (позиция юзера по горизонтали, 1..n)
  // user_position_row (позиция юзера по вертикали, снизу-вверх, 1..n)
  SHARE: "share",

  // юзер кликнул на кнопку копировать имена пользователей
  // параметры: id (наш идентификатор коллажа), provider (название поток шаринга)
  SHARE_POPUP_COPY: "share_popup_copy",
};

export const hits = {
  // --
};

export function hitEvent(id) {
  if (window.appConfig.isDebug) {
    console.info("hitEvent", id);
  }

  if (window.appConfig.hits.isEnabled) {
    window.axios.post(`${window.appConfig.hits.endpoint}?id=${id}&r=${Date.now()}`)
      .then(() => {/* dummy */})
      .catch(console.error);
  }
}

export function logEvent(eventId, eventParams, cb) {
  if (window.appConfig.isDebug) {
    console.info("logEvent", eventId, eventParams);
  }

  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().logEvent(eventId, eventParams || {}, cb);
  }

  if (window.appConfig.analytics.isEnabled) {
    let userParams = undefined;
    if (userPropertiesCommitWaited.length > 0) {
      userParams = {};
      userPropertiesCommitWaited.forEach((key) => userParams[key] = userProperties[key]);
      userPropertiesCommitWaited.length = 0;
    }

    api.logEvent(eventId, eventParams, userParams);
  }
}

export function setUserProperty(key, value) {
  userProperties[key] = value;
  userPropertiesCommitWaited.push(key);
}