import {getLocationQueryObject} from "./utils/text";

const SPLIT_LANDING_TEXT = "split__landing_text";
const SPLIT_PROCESSING_TEXT = "split__processing_text";

const query = getLocationQueryObject();

window.clientConfig = {
  isWeb: typeof query["aid"] === "undefined" && typeof query["vicman_unified_id"] === "undefined",
  isWebview: typeof query["aid"] !== "undefined" || typeof query["vicman_unified_id"] !== "undefined",
  isWebviewAndroid: typeof query["aid"] !== "undefined",
  isWebviewIOS: typeof query["vicman_unified_id"] !== "undefined",
  token: query["aid"] || query["vicman_unified_id"] || window.localStorage.getItem("client_token") || undefined,
  locale: query["locale"] || query["lang"] || "en",
  splits: {
    landing_text: localStorage.getItem(SPLIT_LANDING_TEXT) || undefined,
    processing_text: localStorage.getItem(SPLIT_PROCESSING_TEXT) || undefined,
  },
};

if (typeof window.clientConfig.token !== "undefined") {
  window.axios.defaults.headers.common["X-Client-Token"] = window.clientConfig.token;
}

if (typeof window.clientConfig.splits.landing_text === "undefined") {
  const variants = ["v7", "v8", "v9", "v10", "v11"];
  const selectedVariant = variants[Math.floor(Math.random() * variants.length)];
  localStorage.setItem(SPLIT_LANDING_TEXT, selectedVariant);
  window.clientConfig.splits.landing_text = selectedVariant;
}

if (typeof window.clientConfig.splits.processing_text === "undefined") {
  const variants = ["v1", "v2"];
  const selectedVariant = variants[Math.floor(Math.random() * variants.length)];
  localStorage.setItem(SPLIT_PROCESSING_TEXT, selectedVariant);
  window.clientConfig.splits.processing_text = selectedVariant;
}

// --

window.appConfig = {
  isDebug: process.env.NODE_ENV.startsWith("prod") === false
    || process.env.REACT_APP_DEBUG === "true"
    || query["vicman_debug"]
    || false,

  paths: {
    app: process.env.REACT_APP_APP_PATH,
    assets: process.env.REACT_APP_ASSETS_PATH,
    api: process.env.REACT_APP_API_PATH,
    apiUpload: process.env.REACT_APP_API_FILES_PATH,
  },

  amplitude: {
    isEnabled: process.env.REACT_APP_AMPLITUDE_ENABLED === "true",
    key: process.env.REACT_APP_AMPLITUDE_KEY,
  },

  analytics: {
    isEnabled: process.env.REACT_APP_ANALYTICS_ENABLED === "true",
    endpoint: process.env.REACT_APP_ANALYTICS_ENDPOINT,
  },

  hits: {
    isEnabled: process.env.REACT_APP_HITS_ENABLED === "true",
    endpoint: process.env.REACT_APP_HITS_ENDPOINT,
  },
};