export function debounce(name, delay, func) {
  window.appDebounceTimers = window.appDebounceTimers || {};
  clearTimeout(window.appDebounceTimers[name]);
  window.appDebounceTimers[name] = setTimeout(func, delay);
}

export function resolveFileUrl(file) {
  switch (typeof file) {
    case "string": return file;
    case "object": return file.url;
    default: return "";
  }
}

export function assetUrl(path) {
  const suffix = process.env.REACT_APP_ASSETS_VERSION
      ? ("?" + process.env.REACT_APP_ASSETS_VERSION)
      : "";

  return `${window.appConfig.paths.assets}/${path}${suffix}`;
}

export function buildScreenSizeImagesPath(path, variants) {
  const pos = path.lastIndexOf(".");
  const pair = [path.substring(0, pos), "", path.substring(pos)];
  const res = {};

  variants.forEach((variant) => {
    if (variant !== "1x") {
      pair[1] = "@" + variant;
    }

    res[variant] = pair.join("");
  });

  return res;
}

export function buildScreenSizeImagesSrcString(obj) {
  return Object.keys(obj).map((key) => `${obj[key]} ${key}`).join(", ");
}