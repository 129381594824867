import React from 'react';
import routes from "../routes";
import * as api from "../utils/api";
import AppContext from "../contexts/AppContext";
import {generatePath} from "react-router";
import {resolveFileUrl} from "../utils/etc";
import {logEvent, userEvents} from "../utils/log";

const FETCH_INTERVAL = 1000;
const PHOTO_STATUS_FAILED = -1;
const PHOTO_STATUS_PROCESSED = 1;
const CREATIVE_STATUS_FAILED = -1;
const CREATIVE_STATUS_PROCESSED = 2;

const creativeIsFailed = (creative) => creative.status === CREATIVE_STATUS_FAILED;
const creativeIsProcessed = (creative) => creative.status === CREATIVE_STATUS_PROCESSED;
const creativeIsProcessing = (creative) => !(creativeIsProcessed(creative) || creativeIsFailed(creative));

export default class CreatePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      skip: false,
      isLoading: true,
      photo: null,
      creative: null,
      persons: [],
      selectedPersonsIds: [],
    };

    this.createPhoto = this.createPhoto.bind(this);
    this.fetchPhoto = this.fetchPhoto.bind(this);
    this.handlePhotoStatus = this.handlePhotoStatus.bind(this);
    this.fetchPersons = this.fetchPersons.bind(this);
    this.handleSelectRandomPersonsButtonClick = this.handleSelectRandomPersonsButtonClick.bind(this);
    this.handleCreateCollageButtonClick = this.handleCreateCollageButtonClick.bind(this);
    this.handlePersonClick = this.handlePersonClick.bind(this);

    this.fetchPhotoTimer = null;
  }

  componentDidMount() {
    if (this.props.location.state) {
      if (this.props.location.state.file) {
        this.createPhoto(this.props.location.state.file);
      }
      if (this.props.location.state.selectedPersonsIds) {
        this.setState({
          selectedPersonsIds: this.props.location.state.selectedPersonsIds
        });
      }
      if (this.props.location.state.skip) {
        this.setState({skip: this.props.location.state.skip});
      }
      if (this.props.location.state.photo) {
        this.setState({
          photo: this.props.location.state.photo
        }, this.fetchPhoto);
      }
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.fetchPhotoTimer);
  }

  createPhoto(file) {
    api.createPhoto(file)
      .then(this.handlePhotoStatus)
      .catch((err) => {
        console.error(err);
        this.props.history.replace(routes.ERROR);
      });
  }

  fetchPhoto() {
    api.fetchPhoto(this.state.photo.id)
      .then(this.handlePhotoStatus)
      .catch((err) => {
        console.error(err);
        this.props.history.replace(routes.ERROR);
      });
  }

  handlePhotoStatus(res) {
    if (res.photo.status === PHOTO_STATUS_FAILED) {
      logEvent(userEvents.PROCESSING_FAILED, {type: "usual"});
      this.props.history.replace({pathname: routes.ERROR, state: {message: "failed"}}); // todo
      return;
    } else if (res.photo.status === PHOTO_STATUS_PROCESSED) {
      logEvent(userEvents.PROCESSING_PROCESSED, {type: "usual"});
      this.fetchPersons();
    } else {
      this.fetchPhotoTimer = setTimeout(this.fetchPhoto, FETCH_INTERVAL);
    }

    this.setState({
      photo: res.photo,
      creative: res.creative
    });
  }

  fetchPersons() {
    api.fetchPersons()
      .then((res) => {
        this.setState({persons: res.persons}, () => {
          if (this.state.skip) {
            this.handleSelectRandomPersonsButtonClick(10, this.handleCreateCollageButtonClick);
          } else {
            this.setState({isLoading: false}, this.context.hideLoader);
          }
        })
      })
      .catch((err) => {
        console.error(err);
        this.props.history.replace(routes.ERROR);
      });
  }

  handleCreateCollageButtonClick(e) {
    this.context.showLoader();

    api.createCollage(this.state.photo.id, this.state.selectedPersonsIds)
      .then((res) => {
        this.props.history.replace({
          pathname: generatePath(routes.COLLAGE, {hash: res.collage.hash}),
          state: {collage: res.collage}
        })
      })
      .catch((err) => {
        console.error(err);
        this.props.history.replace(routes.ERROR);
      });
  }

  handleSelectRandomPersonsButtonClick(count, cb) {
    this.setState({
      selectedPersonsIds: this.state.persons
        .map((item) => item.id)
        .sort(() => 0.5 - Math.random())
        .slice(0, count)
    }, () => cb && cb());
  }

  handlePersonClick(e, person) {
    const selectedPersonsIds = this.state.selectedPersonsIds.slice();
    const pos = this.state.selectedPersonsIds.indexOf(person.id);
    if (pos >= 0) {
      selectedPersonsIds.splice(pos, 1);
    } else {
      /*f (this.state.selectedPersonsIds.length >= 10) {
        return;
      }*/

      selectedPersonsIds.push(person.id);
    }

    this.setState({selectedPersonsIds});
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }

    const persons = this.state.persons.sort((a,b) => (a.ig_username > b.ig_username) ? 1 : ((b.ig_username > a.ig_username) ? -1 : 0));

    return <main style={{padding: 20}}>

      <button
        disabled={this.state.selectedPersonsIds.length === 0}
        onClick={this.handleCreateCollageButtonClick}
        children="create collage" />

      <br />
      <br />

      {[...Array(50)].map((v, i) =>
        <React.Fragment key={i}>
          <button
            onClick={(e) => this.handleSelectRandomPersonsButtonClick(i+1)}
            children={i+1} />
          &nbsp;&nbsp;&nbsp;
        </React.Fragment>
      )}

      <hr />

      <img src={resolveFileUrl(this.state.creative.file)} alt="User creative" width={256} />
      <p>It's you</p>

      <hr />

      <div style={{overflow: "hidden"}}>
        {persons.map((person) => <div
            key={person.id}
            onClick={(e) => this.handlePersonClick(e, person)}
            style={{
              height: "250px",
              float: "left",
              opacity: this.state.selectedPersonsIds.indexOf(person.id) >= 0 ? 1 : 0.5,
              border: this.state.selectedPersonsIds.indexOf(person.id) >= 0 ? "1px solid #f00" : "1px solid #fff",
            }}>
            <img src={resolveFileUrl(person.creative.file)} alt="Person creative" width={128} />
            <br />
            <p>{person.ig_username}</p>
            <br />
            <p>{person.name}</p>
          </div>
        )}
      </div>
    </main>
  }
}

CreatePage.contextType = AppContext;
