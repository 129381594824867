import React from "react";
import i18n from "../i18n";
import AppContext from "../contexts/AppContext";

const MAX_TEXT_INDEX = 6;
const LOADING_TEXT_INTERVAL = 5000;

export default class Loading extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      imageIsLoaded: false,
    };

    this.updateTextIndex = this.updateTextIndex.bind(this);

    this.updateTextIndexTimer = null;

    this.image = new Image();
    this.image.onload = () => this.setState({imageIsLoaded: true});
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.hidden !== this.props.hidden) {
      if (this.props.hidden) {
        clearTimeout(this.updateTextIndexTimer);
      }
      else {
        this.updateTextIndex();
      }
    }

    if (prevProps.image !== this.props.image) {
      this.setState({imageIsLoaded: false}, () => {
        this.image.src = this.props.image;
      });
    }
  }

  componentWillUnmount() {
    this.image.onload = () => {};
    clearTimeout(this.updateTextIndexTimer);
  }

  updateTextIndex() {
    const nextIndex = this.context.loader.textIndex + 1;

    if (nextIndex <= MAX_TEXT_INDEX) {
      this.context.setLoaderTextIndex(nextIndex);
    }

    if (nextIndex < MAX_TEXT_INDEX) {
      this.updateTextIndexTimer = setTimeout(this.updateTextIndex, LOADING_TEXT_INTERVAL);
    }
  }

  render() {
    if (this.props.hidden || this.context.loader.textIndex === -1) {
      return null;
    }

    return <main className="loader">
      <div className="spinonediv-1">
        <div className="spinner-wave">
          <div className="spinner-wave2">
            <div className="spinner-wave3">
              <div className="spinner-wave4">
                <div className="loader-image-container">

                  <img 
                    srcSet="/assets/img/icons/img-pl@2x.jpg 2x, /assets/img/icons/img-pl@3x.jpg 3x"
                    src="/assets/img/icons/img-pl.jpg"
                    alt="logo" />

                  {this.props.image && this.state.imageIsLoaded && <img
                    className="avatar-image"
                    src={this.props.image}
                    alt="" />}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p dangerouslySetInnerHTML={{__html: i18n.t(`processing_text_${window.clientConfig.splits.processing_text}_${this.context.loader.textIndex}`) || ""}} />
    </main>;
  }
}

Loading.contextType = AppContext;