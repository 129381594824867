import React from 'react';
import FileChooseButton from "../components/FileChooseButton";
import routes from "../routes";
import * as api from "../utils/api";
import AppContext from "../contexts/AppContext";
import {assetUrl, buildScreenSizeImagesPath, buildScreenSizeImagesSrcString} from "../utils/etc";
import {logEvent, userEvents} from "../utils/log";

export default class IndexPage extends React.Component {

  constructor(props) {
    super(props);

    this.handleFileSelected = this.handleFileSelected.bind(this);
    this.handleRandomPhoto = this.handleRandomPhoto.bind(this);
  }

  componentDidMount() {
    logEvent(userEvents.PAGE_INDEX);
    this.context.hideLoader();
  }

  handleFileSelected(file) {
    logEvent(userEvents.PHOTO_SELECT, {page: "index"});

    this.context.showLoader(true);

    this.props.history.push(routes.CHOOSE, {file});
  }

  handleRandomPhoto() {
    api.fetchRandomPhoto()
      .then((res) => {
        this.props.history.push(routes.CREATE, {photo: res.photo})
      })
      .catch((err) => {
        console.error(err);
        this.props.history.replace(routes.ERROR);
      });
  }

  render() {
    const isDesktop = window.screen.width > 768;
    const backgroundImageName = isDesktop ? "desktop" : "rectangle";

    const backgroundImageWebp = buildScreenSizeImagesPath(
      assetUrl(`assets/img/bg/${backgroundImageName}.webp`),
      isDesktop ? ["1x", "2x"] : ["1x", "2x", "3x"]
    );

    const backgroundImageJpeg = buildScreenSizeImagesPath(
      assetUrl(`assets/img/bg/${backgroundImageName}.jpg`),
      isDesktop ? ["1x", "2x"] : ["1x", "2x", "3x"]
    );

    const textImagePng = buildScreenSizeImagesPath(
      assetUrl(`assets/img/title/${window.clientConfig.splits.landing_text}/text.png`),
      ["1x", "2x", "3x"]
    );

    return <main className="index-page">
      <picture>
        <source
          srcSet={buildScreenSizeImagesSrcString(backgroundImageWebp)}
          type="image/webp" />
        <img
          className="bg-image"
          srcSet={buildScreenSizeImagesSrcString(backgroundImageJpeg)}
          src={backgroundImageJpeg["1x"]}
          alt="Demo" />
      </picture>

      <div className="title-image-conatiner">
        <img
          className="title-image"
          srcSet={buildScreenSizeImagesSrcString(textImagePng)}
          src={textImagePng["1x"]}
          alt="Demo" />
      </div>

      <FileChooseButton
        onFileSelected={this.handleFileSelected}
        className="btn-upload-foto"
        children="Select Photo" />

      {window.appConfig.isDebug &&
        <button
          className="debug"
          onClick={this.handleRandomPhoto}
          children="random photo" />
      }
    </main>;
  }
}

IndexPage.contextType = AppContext;