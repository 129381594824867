import React from "react";
import ReactDOM from "react-dom";
import {Route, Switch, withRouter} from "react-router";
import {BrowserRouter} from "react-router-dom";
import routes from "./routes";
import IndexPage from "./pages/IndexPage";
import ErrorPage from "./pages/ErrorPage";
import CreatePage from "./pages/CreatePage";
import CollagePage from "./pages/CollagePage";
import "./styles/app.scss";
import Loading from "./components/Loading";
import ModalWrapper from "./components/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import * as webviewUtils from "./utils/webview";
import ChoosePage from "./pages/ChoosePage";
import {logEvent, userEvents} from "./utils/log";

class App extends React.Component {

  constructor(props) {
    super(props);

    if (window.clientConfig.isWebview) {
      window.webviewHolders.tabSelected.subscribe(this.handleTabSelected, true);
    }

    this.handleTabSelected = this.handleTabSelected.bind(this);
  }

  componentDidMount() {
    if (window.clientConfig.isWebview) {
      webviewUtils.webviewCheckInstalledApps((apps) => {
        this.context.setInstalledApps(apps);
      });
    }
  }

  handleTabSelected(data) {
    if (data > 0) {
      logEvent(userEvents.TAB_SELECTED);
    }
  }

  render() {
    return (
      <div className={`${window.clientConfig.locale}`}>
        <Switch>
          <Route exact path={routes.INDEX} render={props => <IndexPage {...props} />} />
          <Route exact path={routes.ERROR} render={props => <ErrorPage {...props} />} />
          <Route exact path={routes.CHOOSE} render={props => <ChoosePage {...props} />} />
          <Route exact path={routes.CREATE} render={props => <CreatePage {...props} />} />
          <Route exact path={routes.COLLAGE} render={props => <CollagePage {...props} />} />
        </Switch>
        <ModalWrapper />
        <Loading hidden={this.context.loader.hidden} image={this.context.loader.image} />
      </div>
    );
  }
}

App.contextType = AppContext;

const AppWithRouter = withRouter(App);

ReactDOM.render(
  <BrowserRouter>
    <AppContextProvider>
      <AppWithRouter />
    </AppContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
