export function checkResponse(res) {
  if (res.data.error_code) {
    throw new Error(res.data.error_code);
  } else {
    const token = res.headers["x-client-token"];
    if (token) {
      window.axios.defaults.headers.common["X-Client-Token"] = token;
      window.localStorage.setItem("client_token", token);
    }

    return res.data;
  }
}

export function createPhoto(image, params) {
  params = params || {};

  const formData = new FormData();

  if (image instanceof File) {
    formData.append("file", image);
  } else {
    formData.append("image_url", image);
  }

  if (params.imageData) {
    formData.append("image_data", JSON.stringify(params.imageData || {}));
  }

  if (params.preview) {
    formData.append("preview", "1");
  }

  return window.axios.post(window.appConfig.paths.apiUpload + "/photos/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }).then(checkResponse);
}

export function fetchPhoto(id) {
  return window.axios.get(window.appConfig.paths.api + "/photos/" + id).then(checkResponse);
}

export function fetchRandomPhoto() {
  return window.axios.get(window.appConfig.paths.api + "/photos/random").then(checkResponse);
}

export function fetchPersons() {
  return window.axios.get(window.appConfig.paths.api + "/persons").then(checkResponse);
}

export function submitPhotoTemplate(id, templateId) {
  return window.axios.post(window.appConfig.paths.api + "/photos/" + id + "/template", {template_id: templateId})
    .then(checkResponse);
}

export function createCollage(photoId, personsIds) {
  return window.axios.post(window.appConfig.paths.api + "/collages/create", {
    "photo_id": photoId,
    "persons_ids": personsIds
  }).then(checkResponse);
}

export function fetchCollage(id) {
  return window.axios.get(window.appConfig.paths.api + "/collages/" + id).then(checkResponse);
}

export function logCollageShare(id, provider) {
  return window.axios.post(window.appConfig.paths.api + "/collages/log-share", {id, provider}).then(checkResponse);
}

export function logEvent(eventId, eventParams, userParams) {
  return window.axios.post(window.appConfig.analytics.endpoint, {
    id: eventId,
    params: eventParams,
    user_params: userParams,
  }).then(checkResponse);
}