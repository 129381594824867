module.exports = {
  "strings": {
    "try_another_photo": "Try another photo",
    "go_back": "Go Back",

    "landing__title": "Check how different<br />AI artists see you",
    "landing__select_photo_button": "Upload a photo",

    "processing_text_v1_0": "We are working on your portrait. Just a little bit.",
    "processing_text_v1_1": "Ooh la la, looks great. Aha. 👍",
    "processing_text_v1_2": "We bet your friends will be envious of you!",
    "processing_text_v1_3": "Something unbelievable is on its way… 🚁",
    "processing_text_v1_4": "Oh, we know that celebrity look!",
    "processing_text_v1_5": "Are you excited? We are. 🤩",

    "processing_text_v2_0": "Can’t wait to find out who your BFFs are? We’re almost done! 👯‍",
    "processing_text_v2_1": "Birds of a feather flock together! 🐦",
    "processing_text_v2_2": "Stars are closer than you think. 🌟",
    "processing_text_v2_3": "Guess the celeb: timber just in lake. 🤭",
    "processing_text_v2_4": "We are wishing on stars. 🌠 And you?",
    "processing_text_v2_5": "Are you excited? We are. 🤩",

    "create__step_headline_1": "Tap the result you like most to proceed",
    "create__step_headline_2": "Select one more result...",
    "create__step_headline_3": "And the last one!",
    "create__step_counter": "{{current}} out of {{total}}",

    "create__select_one_result": "Please select the result you like most and tap 'Next' to proceed",
    "create__select_two_results": "Now select two results you like best to create your collage",
    "create__next_button": "Next",
    "create__create_collage_button": "Create collage",

    "collage__title": "Save and share",

    "error__error": "Error",

    "choose_title": "Almost there!",
    "choose_text": "Choose a portrait to add to your collage.",

    "collage_title": "<span>Woohoo!</span><br /> Meet your A-listers squad",
    "collage_text": "Share this exciting news on Instagram, and be sure to tag the celebs you know!",
    "collage_button_download": "Download",

    "modal_title": "Hardly recognize your friends?",
    "modal_text": "Okay, we’ll help you with some names and you can always ask your friends to mention the others.",
    "modal_button": "COPY USERNAMES",
    "modal_postscript": "P.S. Can your friends also find <span>YOU</span> in this collage?",

    "download_modal__title": "Saved! Don’t let it get lost in photos though:",
    "download_modal__text_1": "Make a epic profile pic of it;",
    "download_modal__text_2": "Show off your squad on socials & messengers;",
    "download_modal__text_3": "Send it to your phone’s home screen",
    "download_modal__button": "Got it!",

    "internal_error": "An error occurred...",
  },
};

